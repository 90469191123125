* {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

:root {
  --gold: #c6b378;
}

/* Dot */

.dot {
  position: static;
  width: 4px;
  height: 4px;
  left: 204px;
  top: 6px;
  background: #dbdbdb;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.bg-login {
  background-image: url("../assets/bg-login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon-wrapper {
  position: absolute;
  color: rgba(162, 162, 162, 1);
}

.icon-wrapper.left {
  left: 15px;
  bottom: 12px;
}

.icon-wrapper.right {
  right: 15px;
  bottom: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.default-profile {
  background-color: rgba(230, 240, 255, 1) !important;
}

.default-profile svg path {
  fill: rgba(153, 193, 255, 1) !important;
}

.filter-checkbox span {
  font-size: 12px;
}

.filter-checkbox-wrapper {
  padding: 0 5px;
}

label[data-checked].filter-checkbox {
  background-color: rgba(249, 247, 242, 1);
}

.filter-checkbox .chakra-checkbox__control[data-checked] {
  background: rgba(198, 179, 120, 1);
  border-color: rgba(198, 179, 120, 1);
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.show-sidebar-wrapper {
  position: fixed;
  bottom: 50px;
}

.show-sidebar-wrapper:hover .show-sidebar {
  background-color: #fff;
  border: 1px solid var(--gold);
  transition: 0.3s;
}
.show-sidebar-wrapper:hover svg {
  color: var(--gold);
  transition: 0.3s;
}

.show-sidebar {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  background-color: var(--gold);
  border-radius: 5px;
  cursor: pointer;
}

.show-sidebar svg {
  font-size: 15px;
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.page-number-wrapper button {
  font-size: 14px;
  height: 32px;
  width: 32px;
}

.page-controller,
.pagination-wrapper button {
  border: 1px solid #e9e9e9;
  font-weight: 400;
}

.pagination-wrapper button:focus {
  outline: none;
  box-shadow: none;
}

.navbar-wrapper-hide:hover,
.navbar-wrapper-show {
  width: 240px;
}

.navbar-wrapper-hide {
  width: 50px;
}

.navbar-wrapper-hide .show-sidebar-wrapper {
  left: 58px;
}

.navbar-wrapper-show .show-sidebar-wrapper,
.navbar-wrapper-hide:hover .show-sidebar-wrapper {
  left: 240px;
}

.navbar-wrapper-hide:hover .navbar-item {
  padding-top: 0;
}

.navbar-wrapper-hide .navbar-item {
  padding-top: 50px;
}

.navbar-wrapper-hide .show-sidebar-wrapper,
.navbar-wrapper-hide:hover .show-sidebar-wrapper,
.navbar-wrapper-hide .navbar-item,
.navbar-wrapper-hide:hover .navbar-item,
.navbar-wrapper-show .show-sidebar-wrapper,
.navbar-wrapper-hide:hover,
.navbar-wrapper-hide,
.navbar-wrapper-show {
  transition: 0.3s all;
}

.navbar-wrapper-hide .chakra-collapse {
  display: none!important;
}
/* 
.navbar-wrapper-hide .navbar-item > a > div {
  margin: 0 12px;
} */

.navbar-wrapper-hide:hover .chakra-collapse {
  display: block!important;
}

.custom-file-upload > input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid rgba(198, 179, 120, 1);
  background-color: rgba(198, 179, 120, 1);
  width: 24px;
  max-width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 0;
  transition: .3s;
}

.custom-file-upload:hover {
  transform: scale(1.2);
  transition: .3s;
}

.custom-file-upload > div > svg {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 14px;
}

.custom-file-upload svg path {
  fill: #fff!important;
}

.custom-chakra-avatar {
  background-color: transparent!important;
}

.custom-chakra-avatar img {
  height: auto!important;
  padding: 16px!important;
}

.custom-file-input {
  color: transparent;
  max-width: 24px!important;
  width: 24px!important;;
  height: 24px!important;
  padding-left: 0!important;
  /* padding: 0!important; */
  z-index: 9;
  border-radius: 50%;
}

.custom-file-input:hover {
  transform: scale(1.1);
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: '';
  color: black;
  display: inline-block;
  background: none;
  /* border: 1px solid #999; */
  border-radius: 3px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 40px;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.user-action-wrapper svg {
  width: 10px;
}

.datepicker-wrapper {
  height: 32px;
  font-size: 14px;
}

.custom-table-collapse-show {
  visibility: visible;
  display: table-row;
  animation: fade-in 1s;
}

.custom-table-collapse-hide {
  visibility: hidden;
  display: none;
  animation: fade-out 2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translate(0, 0);
    display: table-row;
  }
  to {
    opacity: 0;
    transform: translate(0, -10px);
    display: none;
  }
}

.datepicker-wrapper > button > span input ~ div,
.datepicker-wrapper > button > span input {
  height: 32px;
  font-size: 12px;
  font-family: 'Inter';
}